import React, { useEffect, useState } from "react";
import "../scss/content.scss"
import {ModailCost} from "../jsx/modail"
import Girl from "../img/girl.png"
import Logo from "../img/logo.png"






export function Content(){
    const [iW,setIW] = useState(window.innerWidth)
    useEffect(()=>{
        window.onresize = ()=>{
            setIW(window.innerWidth)
        }
    })

    return(
        <div className="content">
            <img src={Logo} alt="" className={iW>850? "logo": "logo logo__mini"}/>
            <div className="content_midle">
                
                {
                    iW>850? <img className="img-screen" src={Girl} alt="img" />:""
                }
                <div className="case">
                    <span>DONATE<br />TO UKRAINE<br />TODAY</span>
                    <ModailCost></ModailCost>
                    {
                        iW>850? "":<img className="img-screen" src={Girl} alt="img" />
                    }
                    <span>Save <br />the world<br /> tomorrow</span>
                </div>
            </div>
        </div>
    )
}

export function Footer(){
    return(
        <footer className="footer">
            <span className="footer_title">Raised funds will be used to help Ukrainian families affected by the war in Ukraine</span>
            <span className="footer_sub-title">©2022 , CO "CF Ukrainian Educational Foundation", Biedrība (BDR) "JK NAMS". All rights reserved.</span>
        </footer>
    )
}