import React, { useEffect, useState } from "react";
import Arrow from "../img/arrow.svg"
import "../scss/modail.scss"
import { ButtonCost } from "./button";




export function ModailCost(){
    const [act, stateAct] = useState(false)
    const [id, stateId] = useState(false)

    useEffect(()=>{
        const location = document.location.search
        const idPoster = location.split("=")[1]
        console.log(idPoster)

        if(idPoster === "1"){
            stateId(false)
        }else if(idPoster === "2"){
            stateId(true)
        }else{
            stateId(false)
        }
    
    },[])


    return(
        
        <div className={act? "cost cost__act":"cost"}>
            <div className="cost_title">
                <span>Ukraine - nation of heroes</span>
            </div>
            <div className={act? "cost_button cost_button__act":"cost_button"}>
                <ButtonCost id="5" href={id? "":"https://buy.stripe.com/8wM16e3ru6kn3tudQQ"}></ButtonCost>
                <ButtonCost id="25" href={id? "":"https://buy.stripe.com/7sIaGO2nq7or1lm6oN"}></ButtonCost>
                <ButtonCost id="50" href={id? "":"https://buy.stripe.com/cN2g188LO9wzd44eVk"}></ButtonCost>
                <ButtonCost id="100" href={id? "":"https://buy.stripe.com/3csaGO7HKbEH4xy8wX"}></ButtonCost>
                <ButtonCost id="200" href={id? "":"https://buy.stripe.com/7sI16ebY08svaVW5kM"}></ButtonCost>
                <ButtonCost id="500" href={id? "":"https://buy.stripe.com/4gw2aie685gj0hiaF7"}></ButtonCost>
                <ButtonCost id="1000" href={id? "":"https://buy.stripe.com/eVadT09PS9wz2pq4gK"}></ButtonCost>
            </div>
            <div className="cost_less" onClick={()=>{stateAct(!act)}}>
                <img src={Arrow} alt="" className={act? "arrow arrow__act":"arrow"}/>
                <span>{act? "Load less": "Load more"}</span>
            </div>
        </div>
    )
}
